<template>
    <v-container fluid>
        <v-form v-model="isFormValid">
            <v-card>
                <v-card-title>
                    <v-container>
                        <v-row>
                            <v-col :cols="12">
                                {{ title }}
                            </v-col>
                        </v-row>

                        <parameters v-model="parameters" />

                        <v-row>
                            <v-col>
                                <v-btn :disabled="!isFormValid" @click="runQuery">
                                    Build Report
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-title>

                <v-card-text>
                    <report-table
                        :items="items"
                        :headers="filteredHeaders"
                        :query-loading="isQueryLoading"
                        :title="title" />
                </v-card-text>
            </v-card>
        </v-form>
    </v-container>
</template>

<script>
import axios from 'axios'
import { handleError } from "@/services/errorUtility";
import { DateTime } from 'luxon'

export default {
    components: {
        reportTable: () => import('../reports/ReportTable'),
        parameters: () => import('../reports/Parameters')
    },

    data() {
        return {
            isQueryLoading: false,
            title: "Sample Report",
            isFormValid: false,

            headers: [
                { text: "Test Group", value: "testGroup", property: "testGroupId" },
                { text: "Test", value: "test", property: "testId" },
                { text: "Lab", value: "lab", property: "labId" },
                { text: "Floral Variety", value: "floralVariety", property: "floralVarietyId" },
                { text: "Sample", value: "sample", property: "sampleId" },
                { text: "Result", value: "result", property: "result" },
                { text: "Precision", value: "precision", property: "precision" }
            ],
            items: [],
            parameters: [
                {
                    component: () => import('./DateParameter'),
                    field: { label: "From", property: "from" }
                },
                {
                    component: () => import('./DateParameter'),
                    field: { label: "To", property: "to", value: this.getFilterToDate() }
                },
                {
                    component: () => import('./AutocompleteParameter'),
                    field: {
                        name: "Lab",
                        property: "labId",
                        pascalPlural: 'Labs'
                    }
                },
                {
                    component: () => import('./AutocompleteParameter'),
                    field: {
                        name: "Test Group",
                        property: "testGroupId",
                        pascalPlural: 'TestGroups'
                    }
                },
                {
                    component: () => import('./AutocompleteParameter'),
                    field: {
                        name: "Test",
                        property: "testId",
                        pascalPlural: 'Tests'
                    }
                },
                {
                    component: () => import('./AutocompleteParameter'),
                    field: {
                        name: "Floral Variety",
                        property: "floralVarietyId",
                        pascalPlural: 'FloralVarieties'
                    }
                },
                {
                    component: () => import('./AutocompleteParameter'),
                    field: {
                        name: "Sample",
                        property: "sampleId",
                        pascalPlural: 'Samples'
                    }
                },
            ]
        }
    },
    computed: {
        filteredHeaders() {
            return this.headers.filter(e => {
                let parameter = this.parameters.filter(p =>
                    p.property === e.property)[0];
                if (this.isEmpty(parameter)) {
                    return true;
                }
                return this.isEmpty(parameter.value);
            });
        },
    },
    watch: {
        parameters: {
            deep: true,
            handler() {
                if (this.parameters.every(e => e.field.value != null)) {
                    this.isFormValid = true;
                }
                let testParameter = this.parameters.find(e => e.field.property === "testId");
                testParameter.filter = this.getTestFilter();
            }
        }
    },

    methods: {
        async runQuery() {
            this.isQueryLoading = true;
            try {
                let params = {};
                this.parameters.forEach(e => params[e.field.property] = e.field.value);
                let response = await axios.get('/api/SampleReport/', { params });
                this.items = response.data;
            }
            catch (error) {
                handleError(error);
            }
            finally {
                this.isQueryLoading = false;
            }
        },
        getFilterToDate() {
            return DateTime.local().toISODate();
        },
        getTestFilter() {
            let testGroupId = this.parameters
                .find(e => e.field.property === "testGroupId")
                .field
                .value;
            if (!this.isEmpty(testGroupId)) {
                return { testGroupId }
            }
            return null;
        },
    }
}
</script>
